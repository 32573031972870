export const inputParts = [
  {
    _id: 1,
    tag: "input",
    label: { en: "Name", ja: "お名前" },
    type: "text",
    id: "name",
    value: "this.state.name",
    autoFocus: false,
    row: "",
  },
  {
    _id: 2,
    tag: "input",
    label: { en: "Email address", ja: "メールアドレス" },
    type: "email",
    id: "email",
    value: "this.state.email",
    autoFocus: false,
    row: "",
  },
  {
    _id: 3,
    tag: "input",
    label: { en: "Title", ja: "件名" },
    type: "title",
    id: "title",
    value: "this.state.title",
    autoFocus: false,
    row: "",
  },
  {
    _id: 4,
    tag: "textarea",
    label: { en: "Message", ja: "メッセージ" },
    type: "",
    id: "message",
    value: "this.state.message",
    autoFocus: false,
    row: 14,
  },
]

export const buttonText = {
  en: "Submit",
  ja: "送信",
}

export const afterSentMessage = {
  success: {
    en: "Thank you so much for your message!\nYou'll see my feedback soon.",
    ja:
      "お問い合わせいただきありがとうございます。\n後ほどお返事いたしますので、しばらくお待ち下さい。",
  },
  fail: {
    en:
      "Oops.. Sorry, your message wasn't sent. Could you please send it via Facebook Messenger?",
    ja:
      "申し訳ありません！\nメールが送信されませんでした。お手数ですが、Facebookからご連絡いただけますでしょうか？",
  },
}
