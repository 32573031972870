import React, { useState, useEffect } from "react"
import { navigate } from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import { inputParts, buttonText } from "../data/contact.js"
import Joi from "joi-browser"
import { validate, handleChange } from "../utils/form-functions";
import contactStyles from "./css/contact.module.styl"
const { contact, error, buttonWrap } = contactStyles

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Contact({ location }) {
  //// State ////
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})

  //// intl ////
  const intl = useIntl()
  const language = intl.locale

  //// Joi Schema ////
  const schema = {
    name: Joi.string()
      .trim()
      .required()
      .label(" Name "),
    email: Joi.string()
      .trim()
      .email()
      .required()
      .label(" Email "),
    title: Joi.string()
      .trim()
      .required()
      .label(" Title "),
    message: Joi.string()
      .trim()
      .required()
      .label(" Message "),
  }

  //// Function ////
  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <Layout location={location}>
      <SEO
        location={location}
        lang={language}
        title={language !== "ja" ? "Contact" : "お問い合わせ"}
        keywords={[`Contact`, `お問い合わせ`, `放射線像`, `Autoradiograph`]}
        description={language !== 'ja' ? `Please contact us from here.` : `お問い合わせはこちらから。`}
      />
      <section className={`${contact} component`}>
        <h1>Contact</h1>
        <form
          name="Autoradiograph cotact form"
          method="post"
          action={`https://www.autoradiograph.org/${language}/thanks/`}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          {inputParts.map(part => (
            <React.Fragment key={part.id}>
              <label htmlFor={part.id}>{part.label[language]}</label>
              {part.tag === "input" && (
                <input

                  // autoFocus={part.autoFocus && "autoFocus"}
                  type={part.type}
                  name={part.id}
                  id={part.id}
                  value={state[part.id] || ""}
                  onChange={e => handleChange(e, state, errors, schema, setState, setErrors)}
                />
              )}
              {part.tag === "textarea" && (
                <textarea
                  // autoFocus={part.autoFocus && "autoFocus"}
                  type={part.type}
                  name={part.id}
                  id={part.id}
                  value={state[part.id]}
                  onChange={e => handleChange(e, state, errors, schema, setState, setErrors)}
                  rows={part.row}
                />
              )}
              {errors[part.id] && (
                <div className={error}>{errors[part.id]}</div>
              )}
            </React.Fragment>
          ))}

          <div className={buttonWrap}>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={validate(state, schema)}
            >
              {buttonText[language]}
            </button>
            {/* Sending spinner */}
            {/* {isSending && (
              <div class="la-ball-scale-multiple">
                <div></div>
                <div></div>
                <div></div>
              </div>
            )} */}
          </div>
        </form>
      </section>
    </Layout>
  )
}
