import Joi from "joi-browser"

export const validateProperties = ({ name, value }, schema) => {
    const obj = { [name]: value }
    const eachSchema = { [name]: schema[name] }
    const { error } = Joi.validate(obj, eachSchema)
    return error ? error.details[0].message : null
}

export const validate = (state, schema) => {
    const options = { abortEarly: false }
    const { error } = Joi.validate(state, schema, options)
    if (!error) return null

    const errors = {}
    for (let item of error.details) {
        errors[item.path[0]] = item.message
    }
    return errors
}

export const handleChange = ({ currentTarget: input }, state, errors, schema, setState, setErrors) => {
    //validation
    const currentErrors = errors
    const errorMessage = validateProperties(input, schema)
    setErrors({ ...currentErrors, [input.name]: errorMessage })

    //inputs to state
    input.name === 'zip01' || input.name === 'zip02' || input.name === 'email' ?
        setState({ ...state, [input.name]: input.value.trim() })
        : setState({ ...state, [input.name]: input.value })

}

export const zenhan = (a) => {
    // 10進数の場合
    return a = a.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
        return String.fromCharCode(
            s.charCodeAt(0) - 65248
        );
    });

    // // 16進数の場合
    // a = a.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
    //     return String.fromCharCode(
    //         s.charCodeAt(0) - 0xFEE0
    //     );
    // });

}